import { Component, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { getImage } from "src/app/constants/images.constants";
import { Subscription } from "rxjs";
import { absoluteSignUpURL, AUTH_TYPE, LOGIN_FLOW, SIGNIN_SUCCESS_TOAST, subscribeURL } from "src/app/constants/common.constants";
import { subscriptionAnonymousMsg } from "src/app/constants/subscription.constants";
import { NewComicService } from "src/app/new-comic.service";
import { AuthService } from "src/app/services/auth.service";
import { ComicService } from "src/app/services/comic.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LoginService } from "src/app/services/login.service";
import { isAnonymousUser } from "src/app/utilities/common.util";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { MergeUserModalComponent } from "../merge-user-modal/merge-user-modal.component";

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss', '../../signin/signin.component.scss']
})

export class EmailLoginComponent {
  public emailForm: FormGroup;
  public fetchDone: boolean = true;
  public emailTouched: boolean;
  public isEmailSent: boolean;
  public sendEmailLinkError: string;
  errorEncountered: boolean;
  public currentFlow: string = LOGIN_FLOW.SIGNIN;
  userExistsWithEmail: boolean;
  userNotExistsWithEmail: boolean;
  isMobile: boolean;
  isAnonymousUser: boolean;
  tempError: boolean;
  redirectionType: string;
  message: string;
  comicSeries: any;
  emailSubscription: Subscription;
  redirectionString: string;
  series: any;
  isSubscriber: boolean;
  signInBeforeSubscription: any;
  plan: any;
  // commentModalRef: any;
  modalRef: BsModalRef;
  googleLoading: boolean;
  appleLoading: boolean;


  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private readonly comicService: ComicService,
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private newComicService: NewComicService,
    private loginService: LoginService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService
  ) {
    this.createEmailForm();
    this.initializeCurrentFlow();
    this.isMobile = this.newComicService.isMobileView();
    this.isAnonymousUser = isAnonymousUser();
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
  }

  public initializeCurrentFlow() {
    const url = this.comicService.getCurrentUrl();
    this.signInBeforeSubscription = this.activatedRoute.snapshot.queryParams.signInBeforeSubscription;
    this.plan = this.activatedRoute.snapshot.queryParams.plan;
    const IS_SUBSCRIPTION_FLOW = this.plan && this.signInBeforeSubscription;
    this.currentFlow = IS_SUBSCRIPTION_FLOW ? LOGIN_FLOW.SUBSCRIPTION : this.loginService.getCurrentFlow(url);

    this.message = this.activatedRoute.snapshot.queryParamMap.get('message');
    if (this.message) this.redirectionType = 'friend_request';

    this.redirectionString = this.activatedRoute.snapshot.queryParamMap.get('showSubscriptionMsg') || '';


    const emailID = this.activatedRoute.snapshot.queryParamMap.get('email');
    if (emailID) this.emailForm.patchValue({ email: emailID });

    const series = this.activatedRoute.snapshot.queryParamMap.get('series') || '';
    this.series = series && JSON.parse(series);

    // For email Login Unsuccessfull
    const emailVerificationState = this.activatedRoute.snapshot.queryParamMap.get('emailVerificationState');
    if (emailVerificationState === 'fail') return this.errorEncountered = true;

    // For succesfull email-sent screen
    const otpPattern = /\/email-sent$/;
    if (otpPattern.test(url)) {
      this. isEmailSent = true;
      this.errorEncountered = false;
    }
  }

  private createEmailForm(emailDefault = '') {
    this.emailForm = this.fb.group({
      email: [emailDefault, [Validators.required, this.emailValidator]]
    });
  }

  onBlur() {
    this.emailTouched = true;
  }

  onInput() {
    this.emailTouched = false;
    this.userExistsWithEmail = false;
    this.userNotExistsWithEmail = false;
    this.tempError = false;
    this.sendEmailLinkError = '';
  }

  showEmailError() {
    return this.emailTouched && this.emailForm.get('email').invalid;
  }

  emailValidator(control) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (pattern.test(control.value)) {
      return null; // Validation passed
    } else {
      return { invalidEmail: true }; // Validation failed
    }
  }

  public async sendEmail(emailID: string, isResend = false) {
    this.fetchDone = false;
    const email: string = emailID.toLowerCase();
    try {
      const userExists = await this.authService.checkForExistingUser(AUTH_TYPE.EMAIL, email);

      // if ((this.currentFlow === LOGIN_FLOW.SIGNIN && userExists) || ([LOGIN_FLOW.SIGNUP].includes(this.currentFlow) && !userExists) || ([LOGIN_FLOW.ALERTS.toLowerCase(), LOGIN_FLOW.SUBSCRIPTION.toLowerCase()].includes(this.currentFlow.toLowerCase()) && (this.isAnonymousUser || !userExists))) {
        // If it is SIGNIN flow and user does exists
        // or If it is SIGNUP flow and user does NOT exists
        // or If it is ALERTS flow and (user does NOT exists or is anonymous)
        // or If it is SUBSCRIPTION flow and (user does NOT exists or is anonymous)
        if (![LOGIN_FLOW.ALERTS.toLowerCase(), LOGIN_FLOW.SUBSCRIPTION.toLowerCase()].includes(this.currentFlow.toLowerCase()) && !userExists) {
          await this.authService.sendEmailLink(email, LOGIN_FLOW.SIGNUP, this.redirectionType, this.series, this.plan);
        } else {
          await this.authService.sendEmailLink(email, this.currentFlow, this.redirectionType, this.series, this.plan);
        }
        this.isEmailSent = true;
        this.errorEncountered = false;
        this.userNotExistsWithEmail = false;
        this.userExistsWithEmail = false;
        this.tempError = false;
        this.message = '';
        // Navigate to email-sent
        let route = 'email-sent';
        // if ([LOGIN_FLOW.SIGNUP].includes(this.currentFlow)) route = 'signup/email-sent';
        // this.router.navigate([route], { queryParams: { email: emailID } });

        let queryParams: any = { email: emailID };
        if (this.signInBeforeSubscription && this.plan) {
          queryParams = { ...queryParams, signInBeforeSubscription: this.signInBeforeSubscription, plan: this.plan };
        }
        if (this.series) queryParams['series'] = JSON.stringify(this.series);
        if (this.currentFlow === LOGIN_FLOW.SIGNUP) {
          this.loginService.navigateTo(route, LOGIN_FLOW.SIGNIN, queryParams);
        } else {
          this.loginService.navigateTo(route, this.currentFlow, queryParams);
        }
      // } else if (userExists && [LOGIN_FLOW.SIGNUP].includes(this.currentFlow)) {
      //   // If it is SIGNUP flow and user does exists
      //   this.userExistsWithEmail = true;
      //   this.userNotExistsWithEmail = false;
      //   this.errorEncountered = false;
      //   this.tempError = false;
      //   this.isEmailSent = false;
      // } else if (!userExists && this.currentFlow === LOGIN_FLOW.SIGNIN) {
      //   // If it is SIGNIN flow and user does NOT exists
      //   this.userNotExistsWithEmail = true;
      //   this.userExistsWithEmail = false;
      //   this.errorEncountered = false;
      //   this.tempError = false;
      //   this.isEmailSent = false;
      // } else if ([LOGIN_FLOW.ALERTS.toLowerCase(), LOGIN_FLOW.SUBSCRIPTION.toLowerCase()].includes(this.currentFlow.toLowerCase()) && !this.isAnonymousUser && userExists) { // we need to merge in this case
      //   // If it is ALERTS flow and user does exists and is NOT anonymous
      //   // or If it is SUBSCRIPTION flow and user does exists and is NOT anonymous
      //   await this.authService.sendEmailLink(email, this.currentFlow, this.redirectionType, this.series, this.plan);
      //   this.isEmailSent = true;
      //   this.errorEncountered = false;
      //   this.userNotExistsWithEmail = false;
      //   this.userExistsWithEmail = false;
      //   this.tempError = false;
      //   this.message = '';
      // }

      if (isResend) this.toastr.success('Verification link has been sent.');
      this.fetchDone = true;

    } catch (error) {
      console.log(error.message);
      if (isResend) this.toastr.error(error.message);
      this.sendEmailLinkError = error.message
      this.isEmailSent = false;
      this.fetchDone = true;
    }
  }

  public onSubmit() {
    this.fetchDone = false;
    if (this.emailForm.valid) {
      this.sendEmail(this.emailForm.value.email.trim());
    } else {
      // Mark the field as touched to display validation error
      this.emailForm.markAllAsTouched();
      this.emailTouched = true;
    }
  }

  navigateTo(page?) {
    this.comicSeries = this.activatedRoute.snapshot.queryParams.comic;
    let queryParams: any = {};

    if (this.emailForm.value.email.trim())  queryParams['email'] = this.emailForm.value.email.trim();
    if (this.comicSeries) {
      queryParams['comic'] = this.comicSeries;
      queryParams['message'] = this.message
    }
    if (this.activatedRoute.snapshot.queryParams.showSubscriptionMsg) {
      queryParams['showSubscriptionMsg'] = subscriptionAnonymousMsg;
    }
    if (page) {
      if (page === 'signin') return this.loginService.navigateTo('email', LOGIN_FLOW.SIGNIN, queryParams );
      if (page === 'signup') return this.loginService.navigateTo('dob' , LOGIN_FLOW.SIGNUP, queryParams );
    } else {
      if (this.signInBeforeSubscription && this.plan) {
        queryParams = { ...queryParams, signInBeforeSubscription: this.signInBeforeSubscription, plan: this.plan };
      }
      this.loginService.navigateTo('phone/number', this.currentFlow, queryParams)
    }
  }

  hasAnyError() {
    if (this.userExistsWithEmail) return true;
    if (this.userNotExistsWithEmail) return true;
    if (this.tempError) return true;
    if (this.showEmailError()) return true;
    if (this.sendEmailLinkError) return true;
    return false;
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  async googleLogin() {
    this.googleLoading = true;
    const isSignedInUser = this.authService.isSignedInUser();
    let data;
    if (isSignedInUser) {
       data = await this.authService.linkWithGoogle();
       if (data.errorCantMerge) {
        this.openMergeModal();
        this.googleLoading = false;
       }
    } else {
      data = await this.authService.signInWithGoogle();
    }
    if (data && data.success) {
      this.successRedirection();
    } else {
      this.googleLoading = false;
    }
  }

  openMergeModal() {
    this.modalRef = this.modalService.show(MergeUserModalComponent, {
      class: 'modal-content',
      ignoreBackdropClick: false
    });
  }

  async appleLogin() {
    this.appleLoading = true;
    const isSignedInUser = this.authService.isSignedInUser();
    let data;
    if (isSignedInUser) {
       data = await this.authService.linkWithApple();
       if (data.errorCantMerge) {
        this.openMergeModal();
        this.appleLoading = false;
       }
    } else {
      data = await this.authService.signInWithApple();
    }
    if (data && data.success) {
      this.successRedirection();
    } else {
      this.appleLoading = false;
    }
  }

  successRedirection() {
    this.localStorageService.setItem('isAnonymousUser', false);
    if (this.currentFlow === LOGIN_FLOW.ALERTS) {
      const queryParams = { emailVerificationState: 'success' };
      if (this.series.title !== '') queryParams['series'] = JSON.stringify(this.series);
      return this.router.navigate(['manage-alerts'], { queryParams });
    } else if (this.currentFlow === LOGIN_FLOW.SIGNIN) {
      if (this.signInBeforeSubscription && this.plan) {
        const queryParams = { signInBeforeSubscription: this.signInBeforeSubscription, plan: this.plan };
        this.router.navigate([subscribeURL], { queryParams });
        return;
      } else {
        this.successfulLogin();
      }
    } else if (this.currentFlow === LOGIN_FLOW.SIGNUP) {
      // const param = { last4DigitPhone: this.phoneFormNumber ? `x${this.phoneFormNumber.substr(-4)}` : null }
      // this.router.navigate([`${absoluteSignUpURL}/profile`], { queryParams: this.comicSeries ? { message: this.message, comic: this.comicSeries, ...param }: {...param} });
      // this.router.navigate(['signup'], { queryParams: this.comicSeries ? { message: this.message, comic: this.comicSeries, success: true }: null });
      this.successfulLogin();
    } else {
      this.router.navigate(['/']);
    }
  }

  public successfulLogin() {
    this.toastr.success(SIGNIN_SUCCESS_TOAST);
    this.comicService.getUserDetails().then(res => {
      const userDetails = res.data;
      const pIDs = [];
      if (userDetails.subscriptions.length) {
        userDetails.subscriptions.map((res) => {
          pIDs.push(res.productID);
        });
      }
      if (pIDs.length) this.localStorageService.setItem('productID', JSON.stringify((pIDs)));
    });
    let queryParams = {};
    if (this.message) {
      queryParams = { queryParams: { message: this.message } };
    }
    if (this.redirectionType === 'friend_request') {
      return this.router.navigate(['friend-request']);
    }
    if (this.comicSeries && ![absoluteSignUpURL, '/tinyview'].includes(this.comicSeries)) {
      return this.router.navigate([this.comicSeries], queryParams);
    } else {
      this.router.navigate(['']);
    } 
  }
}
