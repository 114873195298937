<div class="open-in-app py-4" *ngIf="showBanner">
  <div *ngIf="showDiv1" [ngClass]="{'border-rounded pl-3 pr-2': !isTopBanner, 'px-4': isTopBanner}"  @carousel class="carousel-item">
      <!-- Message -->
      <div class="my-auto mr-auto" *ngIf="bannerDetails.message">
        <div class="toast-text body-mini" [innerHTML]="bannerDetails.message"></div>
      </div>
  
      <!-- Button -->
      <div class="ml-3 ml-sm-5 text-nowrap ml-auto my-auto" [class.mr-2]="!isTopBanner" *ngIf="bannerDetails.button.action">
        <button class="body-mini ml-2" [ngClass]="{'banner-loader': showLoader, 'mx-2': showLoader}" (click)="onAction(bannerDetails.button.action)">
          <p class="text-white body-mini" *ngIf="!showLoader">{{ bannerDetails.button.text }}</p>
          <div class="loader-bar-btn spinner" *ngIf="showLoader"></div>
        </button>
      <!-- Cross-btn -->
        <a *ngIf="isTopBanner" (click)="closeBanner(bannerDetails.priority)" class="text-white ml-3"><img [src]="getImagePath('ICONS8_DELETE')" class="toast-text-png" alt="x" width="12px" height="12px"></a>
      </div>  
  </div>

  <div *ngIf="!showDiv1" [ngClass]="{'border-rounded pl-3 pr-2': !isTopBanner, 'px-4': isTopBanner}" @carousel class="carousel-item d-flex justify-content-between">
      <img alt="icon" class="my-auto tv-icon mr-2" [src]="getImagePath('TINYVIEW_MARK_AND_LOGO')">
      <!-- Button -->
      <div class="ml-3 ml-sm-5 text-nowrap ml-auto my-auto" [class.mr-2]="!isTopBanner">
        <button class="body-mini ml-2" [ngClass]="{'banner-loader': showLoader, 'mx-2': showLoader}" (click)="openApp(QRModal)">
          <p class="text-white body-mini">{{openAppLabel}}</p>
        </button>
      <!-- Cross-btn -->
        <a (click)="closeOpenAppCard()" class="text-white ml-3"><img [src]="getImagePath('ICONS8_DELETE')" class="toast-text-png" alt="x" width="12px" height="12px"></a>
      </div>
  </div>
</div>


<ng-template #QRModal>
  <div class="action-container">
		<div>
			<button type="button" class="ml-auto d-flex" aria-label="Close" (click)="modalRef.hide()">
				<img [src]="getImagePath('ICONS8_DELETE')" class="cross-btn"/>
		 </button>
		</div>
		<div class="mx-4 px-1 my-4 text-center">
			<img [src]="getImagePath('BADGE_TINYVIEW_100')" width="32px" height="32px" alt="">
			<div class="my-3 mx-auto qr-container">
				<img width="164px" width="164px" [src]="getImagePath('INSTALL_APP__Q_R_CODE.JPEG')" alt="scan-qr">
			</div>
			<p>Scan to open the app</p>
		</div>
  </div>
</ng-template>