<div class="open-in-app p-2 justify-content-between" *ngIf="message">
    <span class="toast-text body-mini">{{message}}</span>
    <a (click)="message=''"><img class="toast-text-png" [src]="getImagePath('ICONS8_DELETE')"
            alt="x" width="12px" height="12px"></a>
</div>
<div [class.email-container]="currentFlow !== 'SIGNUP'">
    <div *ngIf="!isEmailSent && !errorEncountered" [class.tv-card]="currentFlow !== 'SIGNUP'">
        <div *ngIf="redirectionString" class="redirection-text">{{ redirectionString }}</div>
        <h2 *ngIf="currentFlow === 'SIGNUP'" class="mb-3 pb-1">Email Address</h2>
        <h1 *ngIf="currentFlow === 'SIGNIN'" class="mb-4">Sign In</h1>
        <h1 *ngIf="currentFlow === 'ALERTS' || currentFlow === 'SUBSCRIPTION'" class="mb-4">Email Address</h1>
        <!-- Email FORM started -->
        <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
            <p class="phone-label body-mini">Email Address*
                <!-- <span *ngIf="currentFlow !== 'ALERTS'" class="ml-2 undeline-link" (click)="navigateTo()">
                    Use phone number instead
                </span> -->
            </p>
            <input type="email" placeholder="Enter your email address" (blur)="onBlur()" (input)="onInput()"
                [ngClass]="{ 'invalid': hasAnyError(), 'filled-and-valid': !hasAnyError() && !emailForm.get('email').pristine}"
                formControlName="email">
            <div *ngIf="showEmailError()">
                <div *ngIf="emailForm.get('email').errors?.required" class="error-text mt-2"><img
                        [src]="getImagePath('ICONS8_EXCLAMATION_100')" height="16px" width="16px"><span
                        class="copy-message">Required field.</span></div>
                <div *ngIf="!emailForm.get('email').errors?.required && emailForm.get('email').hasError('invalidEmail')" class="error-text mt-2"><img
                        [src]="getImagePath('ICONS8_EXCLAMATION_100')" height="16px" width="16px"><span
                        class="copy-message">Please enter a valid email address.</span></div>
            </div>
            <div *ngIf="sendEmailLinkError" class="error-text mt-2"><img
                    [src]="getImagePath('ICONS8_EXCLAMATION_100')" height="16px" width="16px"><span
                    class="copy-message">{{sendEmailLinkError}}</span></div>
            <div *ngIf="!emailForm.invalid && userExistsWithEmail" class="error-text mt-2"><img
                [src]="getImagePath('ICONS8_EXCLAMATION_100')" height="16px" width="16px"><span
                class="copy-message">Already a user. Please <span class="tv-link" (click)="navigateTo('signin')">Sign In</span> instead.</span></div>
            <div *ngIf="!emailForm.invalid && userNotExistsWithEmail" class="error-text mt-2"><img
                [src]="getImagePath('ICONS8_EXCLAMATION_100')" height="16px" width="16px"><span
                class="copy-message">
                    <span *ngIf="!isSubscriber">
                        Email address not found. Please <span class="tv-link" (click)="navigateTo('signup')">Sign Up</span>
                        instead.
                    </span>
                    <span *ngIf="isSubscriber">
                        You're a premium user but please <span class="tv-link" (click)="navigateTo('signup')">Sign Up</span>
                        to enjoy full benefits.
                    </span>
                </span>
            </div>
            <div *ngIf="!emailForm.invalid && tempError" class="error-text mt-2"><img
                [src]="getImagePath('ICONS8_EXCLAMATION_100')" height="16px" width="16px"><span
                class="copy-message">A user with this email address already exists.</span></div>
            <!-- Showing this permanently until there is any error -->
            <div *ngIf="!hasAnyError()" class="body-mini mt-1">We will send verification link to this email address.
            </div>

            <!-- Button to send Link -->
            <!-- 'userExistsWithEmail' check to disable -->
            <button [disabled]="hasAnyError() || this.emailForm.invalid || !this.emailForm.get('email').value" class="btn tv-btn-red send-btn"
                id="send-otp-btn">
                <span class="loader-bar" *ngIf="!fetchDone"></span>
                <span class="p-bold send-btn-text" *ngIf="fetchDone">Send Verification Link</span>
            </button>

            <!-- This is a conditional block depending on SIGNIN/SIGNUP flow -->
            <!-- <p class="mt-20 text-center" *ngIf="currentFlow === 'SIGNIN'">
                New user? <span class="tv-link" (click)="navigateTo('signup')">Sign Up</span>
            </p> -->
        </form>
        <div *ngIf="currentFlow === 'ALERTS'" class="mt-58">
            <div class="d-flex"><div class="separator-line"></div><div class="mx-2">or</div><div class="separator-line"></div></div>
            <div>
                <!-- <button class="btn tv-btn-red send-btn" (click)="navigateToMailSignin()"><img [src]="getImagePath('ICONS8_EMAIL_100_FFFFFF')" alt="Email-icon" class="social-icon">Sign in with Email</button> -->
                <button class="btn dark-btn send-btn dark mb-3" (click)="googleLogin()"><div *ngIf="googleLoading" class="mx-auto loader-dots-white" alt="loader"></div><span class="p-bold" *ngIf="!googleLoading"><img [src]="getImagePath('ICONS8_GOOGLE_100')" alt="Google-Icon" class="social-icon">Sign in with Google</span></button>
                <button class="btn dark-btn send-btn dark no-margin" (click)="appleLogin()"><div *ngIf="appleLoading" class="mx-auto loader-dots-white" alt="loader"></div><span class="p-bold" *ngIf="!appleLoading"><img [src]="getImagePath('ICONS8_APPLE_100_FFFFFF')" alt="Apple-Icon" class="social-icon">Sign in with Apple</span></button>
            </div>
        </div>
    </div>

    <div *ngIf="isEmailSent && !errorEncountered" [class.tv-card]="currentFlow !== 'SIGNUP'">
        <h2 *ngIf="currentFlow === 'SIGNUP'" class="mb-3 pb-1">Check Your Email</h2>
        <h1 *ngIf="currentFlow !== 'SIGNUP'" class="mb-4">Check Your Email</h1>
        <p>
            Link sent to <span class="p-bold">{{emailForm.get('email').value}}</span>. Please click on the link to sign in.
        </p>
        <div class="pt-3" [class.mt-1]="fetchDone">
            <span class="body-mini" [class.d-block]="isMobile">Didn’t receive the email?</span>
            <a class="pr-1" [class.pl-2]="!isMobile"
            (click)="sendEmail(emailForm.get('email').value.trim(), true)" >
            <span *ngIf="fetchDone" class="undeline-link">Resend</span>
            <span class="loader-bar mx-2" *ngIf="!fetchDone"></span>
            </a>
            <span class="border-left">
                <a class="undeline-link ml-1"
            (click)="isEmailSent = false">Change email address</a>
            </span>
        </div>
    </div>

    <div *ngIf="!isEmailSent && errorEncountered" [class.tv-card]="currentFlow !== 'SIGNUP'">
        <h2 *ngIf="currentFlow == 'SIGNUP'" class="mb-3 pb-1" [class.text-center]="currentFlow !== 'SIGNUP'">Email Verification Unsuccessful</h2>
        <h1 *ngIf="currentFlow !== 'SIGNUP'" class="mb-4">Email Verification Unsuccessful</h1>
        <p class="mb-3 pb-1">
            Your email address could not be verified.
        </p>
        <span class="body-mini">Try Again?</span><a class="undeline-link ml-2 mr-1"
            (click)="sendEmail(emailForm.get('email').value.trim())" *ngIf="emailForm.get('email').value.trim()">Resend</a>
            <span class="border-left">
                <a class="undeline-link ml-1"
            (click)="isEmailSent = false; errorEncountered = false">Change email address</a>
            </span>
    </div>
</div>
