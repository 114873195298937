<a (click)="toggleMenu.emit()">
  <img class="cross-btn m-2" [src]="getImagePath('ICONS8_DELETE')"/>
</a>
<div class="mobile-nav-wrapper mt-4">
  <ul class="nav-list">
    <a class="nav-list-item user-img">
      <li class="pb-2">
        <img *ngIf="userImage" [src]="userImage" class="img-user" [class.image-border]="!!userImage"
        (error)="onImgError($event)"/>
        <img *ngIf="!userImage" class="img-user" [src]="getImagePath('ICONS8_MALE_USER_100')" />
        <span class="d-flex" *ngIf="!loggedIn">
          <h1 class="user-name font mb-1">Guest User</h1>
          <span class="mt-1" *ngIf="userBadges && userBadges[0]">
            <img alt="badge-icon" width="16px" height="16px" class="badge-icon ml-1" [src]="getBadgeURL(userBadges[0])">
          </span>
        </span>
      </li>
    </a>
    <div *ngIf="!loggedIn" class="no-hover ml-2" (click)="signIn()">
      <h2 class="px-auto">Sign In</h2>
    </div>
    <!-- <p *ngIf="!loggedIn" class="ml-2 mt-1">
      New user? <span class="tv-link" (click)="signUp()">Sign Up</span>
    </p> -->
    <li class="user-data" *ngIf="userName || userPhoneNum">
      <span class="d-flex">
        <h1 class="user-name font mb-1">{{userName || 'x' + userPhoneNum}}</h1>
        <span class="mt-1" *ngIf="userBadges && userBadges[0]">
          <img alt="badge-icon" class="badge-icon ml-1" [src]="getBadgeURL(userBadges[0])">
        </span>
      </span>
      <p *ngIf="phoneNumber && !isIncompleteProfile" class="body-mini mb-1">{{phoneNumber}}</p>
      <p *ngIf="userEmailName && !isIncompleteProfile" class="body-mini">{{userEmailName}}</p>
    </li>
    <li (click)="navigateToProfileCompletion()" class="error-text user-data mt-2" *ngIf="isIncompleteProfile && loggedIn">
      <span class="tv-link">Complete profile</span>
    </li>
  </ul>
</div>

<div class="mobile-nav-wrapper" *ngIf="!showCreateList && !showLegalList && !showProfileList && !showFriendMenu && !showSettingsMenu && !showAlerts">
  <ul class="nav-list">
    <br>
    <ng-container>
      <a class="nav-list-item" (click)="showProfileSection()">
        <li>
          <img class="nav-icon" [src]="getImagePath('USER')" alt="">
          <p class="d-inline ml-2">Account</p>
          <img *ngIf="isIncompleteProfile" [src]="getImagePath('ICONS8_BOX_IMPORTANT')" class="icon-16 mb-1 ml-2">
          <img class="arrow" [src]="getImagePath('ICONS8_RIGHT_ARROW')"/>
        </li>
      </a>
    </ng-container>
    <a *ngIf="!isSubscribed" [routerLink]="['/tinyview/influence-points']" class="nav-list-item">
      <li>
        <img class="nav-icon" [src]="getImagePath('TINYVIEW_INFLUENCE_POINTS_OUTLINE')" alt="">
        <p class="d-inline ml-2">Influence Points</p>
      </li>
    </a>
    <a (click)="showFriendSection()" class="nav-list-item">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_USER_ACCOUNT')" alt="Friends">
        <p class="d-inline ml-2">Friends</p>
        <img class="arrow" [src]="getImagePath('ICONS8_RIGHT_ARROW')"/>
      </li>
    </a>
    <a (click)="showSettingsSection()" class="nav-list-item">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_SETTINGS')" alt="Settings">
        <p class="d-inline ml-2">Settings</p>
        <img class="arrow" [src]="getImagePath('ICONS8_RIGHT_ARROW')"/>
      </li>
    </a>
    <ng-container *ngIf="loggedIn && currentDevice == 'desktopBrowser'">
      <a class="nav-list-item" (click)="showCreateSection()">
        <li
          *ngIf="(canEdit && (isAdmin || tinyviewAdmin)) || (!canAdd && (isAdmin || tinyviewAdmin)) || (canEdit && (isAdmin || tinyviewAdmin) && canAdd) || (isAnalysts || tinyviewAdmin)">
          <img class="nav-icon" [src]="getImagePath('PENCIL_ICON')" alt="">
          <p class="d-inline ml-2">Creators</p>
          <img class="arrow" [src]="getImagePath('ICONS8_RIGHT_ARROW')"/>
        </li>
      </a>
    </ng-container>
    <a class="nav-list-item" (click)="showLegalSection()">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICON8_GOOD_NOTES')" alt="">
        <p class="d-inline ml-2">Legal</p>
        <img class="arrow" [src]="getImagePath('ICONS8_RIGHT_ARROW')"/>
      </li>
    </a>
    <a class="nav-list-item" href="mailto:support@tinyview.zendesk.com?body={{UID}}">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_MAILING')" alt="">
        <p class="d-inline ml-2">Contact Us</p>
      </li>
    </a>
    <a (click)="signOut()" class="nav-list-item">
      <li *ngIf="loggedIn">
        <img class="nav-icon" [src]="getImagePath('ICONS8_SIGN_OUT')" alt="">
        <p class="d-inline ml-2">Sign Out</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showProfileList">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showProfileSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" [src]="getImagePath('BACK_ARROW')" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a *ngIf="loggedIn" class="nav-list-item" (click)="navigateToProfileCompletion()">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_REGISTRATION')" alt="">
        <p class="d-inline ml-2">Edit Profile</p>
        <img *ngIf="isIncompleteProfile" [src]="getImagePath('ICONS8_BOX_IMPORTANT')" class="icon-16 ml-2">
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="[subscribeURL]">
      <li>
        <img class="nav-icon" src="{{ isSubscribed ? getImagePath('ICONS8_CROWN_100_OUTLINED') : getImagePath('ICONS8_FREE_100_OUTLINED') }}" alt="">
        <p class="d-inline ml-2">Manage Your Plan</p>
      </li>
    </a>
    <a *ngIf="isSubscribed" class="nav-list-item" (click)="manageSubscription()">
      <li>
        <img class="nav-icon" [src]="getImagePath(subDetails.store)" alt="">
        <p *ngIf="subDetails.store === 'Stripe'" class="d-inline ml-2">Stripe Billing</p>
        <p *ngIf="subDetails.store === 'Google'" class="d-inline ml-2">Google Subscriptions</p>
        <p *ngIf="subDetails.store === 'Apple'" class="d-inline ml-2">Apple Subscriptions</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showCreateList">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showCreateSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" [src]="getImagePath('BACK_ARROW')" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item" *ngIf="canAdd && (isAdmin || tinyviewAdmin)">
      <li class="nav-list-item" (click)="onEdit(true,showModal)">
        <img class="nav-icon" [src]="getImagePath('ICONS8_ADD')" alt="">
        <p class="d-inline ml-2">Add Comics</p>
      </li>
      <li class="nav-list-item" *ngIf="!canAdd && (isAdmin || tinyviewAdmin)" style="opacity: 0.5;"
        (click)="onEdit(true,showModal)">
        <img class="nav-icon" [src]="getImagePath('ICONS8_ADD')" alt="">
        <p class="d-inline ml-2">Add Comic</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li *ngIf="canEdit && (isAdmin || tinyviewAdmin)" (click)="onEdit(false,'')">
        <img class="nav-icon" [src]="getImagePath('ICONS8_EDIT')" alt="">
        <p class="d-inline ml-2">Edit Comic</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li *ngIf="(isAdmin || tinyviewAdmin) && isCover && currentUrl != '/'" (click)="openLinkModal(showLinkModal)">
        <img class="nav-icon" [src]="getImagePath('ICONS8_ADD_LINK')" alt="">
        <p class="d-inline ml-2">Add Link</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/notification', currentUrl]"
      *ngIf="canEdit && (isAdmin || tinyviewAdmin) && canAdd && !isCover">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_ADD_REMINDER')" alt="">
        <p class="d-inline ml-2">Schedule Alerts</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/dashboard']" *ngIf="(isAnalysts || tinyviewAdmin)">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_COMBO_CHART')" alt="">
        <p class="d-inline ml-2">Main Dashboard</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/dashboard/creators']" *ngIf="(isAnalysts || tinyviewAdmin || isAdmin)">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_COMBO_CHART')" alt="">
        <p class="d-inline ml-2">Series Dashboard</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/dashboard/referrals']" *ngIf="(isAnalysts || tinyviewAdmin || isAdmin)">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_PAYROLL')" alt="Referral Icon">
        <p class="d-inline ml-2">Referral Revenue</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showLegalList">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showLegalSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" [src]="getImagePath('BACK_ARROW')" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/terms-conditions']">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_TERMS_AND_CONDITIONS')" alt="">
        <p class="d-inline ml-2">Terms of Use</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/privacy-policy']">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_PRIVACY_POLICY')" alt="">
        <p class="d-inline ml-2">Privacy Policy</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showFriendMenu">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showFriendSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" [src]="getImagePath('BACK_ARROW')" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li (click)="openInviteFriendsModal()">
        <img class="nav-icon" [src]="getImagePath('ICONS8_ADD_USER_GROUP_MAN')" alt="Invite Friends">
        <p class="d-inline ml-2">Invite Friends</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/see-friends']">
      <li>
        <img class="nav-icon" [src]="getImagePath('ICONS8_USER_ACCOUNT')" alt="See Friends">
        <p class="d-inline ml-2">See Friends</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/friend-request']">
      <li>
        <img class="nav-icon" [src]="getImagePath('ALARM')" alt="Friend Requests">
        <p class="d-inline ml-2">Friend Requests</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showSettingsMenu">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showSettingsSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" [src]="getImagePath('BACK_ARROW')" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li (click)="showManageAlerts()">
        <img class="nav-icon" [src]="getImagePath('ALARM')" alt="Manage Alerts">
        <p class="d-inline ml-2">Manage Alerts</p>
      </li>
    </a>
  </ul>
</div>

<ng-template #showModal>
  <div class="show-modal">
    <h3>Can’t Add Comic</h3>
    <p>Please create a panel with the title “Latest Comics” and try again. New comics will be added under this panel.
    </p>
    <button class="secondary-btn" aria-label="Open" (click)="modalRef.hide()">
      OK
    </button>
  </div>
</ng-template>
<ng-template #showLinkModal>
  <app-external-link [modalLinkRef]="modalLinkRef" [fromComicPannel]="false"></app-external-link>
</ng-template>
