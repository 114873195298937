<div class="modal-content content">
	<img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="action('closeModal')" />
	<div class="modal-header">
		<h2>{{ modalConfig.header }}</h2>
		<div *ngIf="!modalConfig.subHeader" class="modal-sub-header body-mini">
			You have {{ modalConfig.modalData?.influencePointsBalance || 0 }} influence point{{ (modalConfig.modalData?.influencePointsBalance !== 1) ? 's' : '' }}.
		</div>
		<div *ngIf="modalConfig.subHeader" class="modal-sub-header body-mini">
			{{ modalConfig.subHeader }}
		</div>
	</div>

	<div *ngIf="!modalConfig.buttons.length" class="button-container">
		<a class="light-link" (click)="action('subscribe')">
			<p>Upgrade to a Premium Plan</p>
		</a>
		<a class="light-link" (click)="action('useInfluencePoints')"
			[ngClass]="{ 'disabled-link': !influencePointsBalance, 'p-0': isComicUnlocking }">
			<span *ngIf="!isComicUnlocking">
				<p>Use 1 Influence Point</p>
			</span>
			<img *ngIf="isComicUnlocking" [src]="getImagePath('ICONS8_LOADING_V2')" alt="loader">
		</a>
		<a class="light-link" (click)="action('earnInfluencePoints')">
			<p>Earn Influence Points</p>
		</a>
		<!-- <a class="light-link" (click)="action('restoreSubscription')"> // Waiting on BE for this functionality
            <p>I am a Subscriber
        </a> -->
		<a class="light-link cancel" (click)="action('closeModal')">
			<p>Cancel</p>
		</a>
	</div>

	<div *ngIf="modalConfig.buttons.length" class="button-container">
		<ng-container *ngFor="let btn of modalConfig.buttons">
			<a class="light-link {{btn.class}}" [ngClass]="{ 'disabled-link': btn.disabled, 'p-0': btn.loading }"
				(click)="action(btn.action)">
				<span *ngIf="!btn.loading">
					<p>{{ btn.text }}</p>
				</span>
				<img *ngIf="btn.loading" [src]="getImagePath('ICONS8_LOADING_V2')" alt="loader">
			</a>
		</ng-container>
	</div>
</div>