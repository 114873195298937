import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SIGNIN_SUCCESS_TOAST, subscribeURL } from "src/app/constants/common.constants";
import { getImage } from "src/app/constants/images.constants";
import { AuthService } from "src/app/services/auth.service";
import { ComicService } from "src/app/services/comic.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { environment } from "src/environments/environment";

const DOMAIN_NAME = environment.DOMAIN_NAME;

@Component({
  selector: 'app-email-landing-page',
  templateUrl: './email-landing-page.component.html',
  styleUrls: ['./email-landing-page.component.scss']
})

export class EmailLandingPageComponent {
  currentFlow: string = 'alerts';
  navigationConfig = {
    fail: {
      signup: 'signup/email',
      signin: 'signin/email',
      alerts: 'verification/email'
    },
    success: {
      signup: 'signup',
      signin: 'signin-success',
      alerts: 'manage-alerts'
    }
  }
  emailID: string;
  uuid: string;
  emailLink: string;
  redirectionType: string;
  series: any;
  plan: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private platform: object,
    private readonly router: Router,
    private toastr: ToastrService,
    private readonly comicService: ComicService,
    private localStorageService: LocalStorageService
  ) {
    this.initVerification();
    if (isPlatformBrowser(this.platform)) {
      this.checkForVerifyEmailHistory();
    }
  }

  // To avoid back propagation, to show unsuccessful email-verification screen
  checkForVerifyEmailHistory() {
    if (window) {
      window.history.replaceState({}, "", "");
    }
  }

  public async initVerification() {
    let userID: string = this.activatedRoute.snapshot.queryParamMap.get('uuid');
    let emailLink: string = DOMAIN_NAME + this.router.url;

    if (userID) {
      try {
        const data = await this.authService.emailAuthorization(emailLink, userID);
        this.currentFlow = data.currentFlow;
        this.redirectionType = data.redirectionType;
        this.series = data.series;
        this.plan = data.plan;
        this.navigateTo('success');
      } catch (error) {
        this.emailID = error.email;
        this.currentFlow = error.currentFlow;
        this.navigateTo('fail');
      }
    }
  }
  async navigateTo(state: string) {
    const uriSignin = this.navigationConfig[state].signin;
    const uriSignup = this.navigationConfig[state].signup;
    const uriAlerts = this.navigationConfig[state].alerts;
    if (state === 'success') {
      this.localStorageService.setItem('isAnonymousUser', false);
    }
    const email = state === 'fail' ? this.emailID : ''
    switch (this.currentFlow) {
      case 'signin':
      case 'signup':
        if (state === 'success') {
          this.toastr.success(SIGNIN_SUCCESS_TOAST);
          const res = await this.comicService.getUserDetails();
          const userDetails = res.data;
          const pIDs = [];
          if (userDetails.subscriptions.length) {
            userDetails.subscriptions.map((res) => {
              pIDs.push(res.productID);
            });
          }
          if (pIDs.length) this.localStorageService.setItem('productID', JSON.stringify((pIDs)));
          this.router.navigate(['']);
        } else {
          this.router.navigate([uriSignin], { queryParams: { emailVerificationState: state, email, redirectionType: this.redirectionType } });
        }
        break;
      case 'subs_sign_in':
        debugger;
        this.router.navigate([subscribeURL], { queryParams: { signInBeforeSubscription: true, plan: this.plan } });
        break;
      case 'alerts':
      case 'merge':
        const queryParams = { emailVerificationState: state };
        if (this.series.title !== '') queryParams['series'] = JSON.stringify(this.series);
        this.router.navigate([uriAlerts], { queryParams });
        break;
    }
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
