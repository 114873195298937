import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { getImage } from "src/app/constants/images.constants";
import { NewComicService } from "src/app/new-comic.service";
import { ComicService } from "src/app/services/comic.service";
import { environment } from "src/environments/environment";
const COMIC_IMG_API = `${environment.API_END_POINT}`;
@Component({
  selector: 'app-scroller',
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.scss']
})
export class ScrollerComponent implements OnInit, AfterViewInit {
  hideScrollbarTimeout: any; // type Timer = /*unresolved*/ any
  @Input('panelConfigData') configData: any;
  scrollContainer: any;
  isStoryPage: boolean;
  firstTimeScrolled: boolean;
  // @ViewChild('viewport', { static: true }) scrollContainer!: ElementRef;  

  constructor(
    private newComicService: NewComicService,
    private comicService: ComicService,
    private router: Router,
    public viewport: ElementRef
  ) { }

  ngAfterViewInit(): void {
    this.prepareRenderingData();
  }

  ngOnInit(): void {
    this.isStoryPage = this.newComicService.isStoryPage();
    setTimeout(() => {
      if (!this.firstTimeScrolled) {
        this.scrollToCenter(this.scrollContainer);
        this.firstTimeScrolled = true;
      }
    }, 1000)
  }

  prepareRenderingData() {
    const currentUrl = this.isStoryPage ? this.configData.currentComicAction : this.comicService.getCurrentUrl();
    this.scrollContainer = this.viewport.nativeElement.querySelector('#viewport');
    if (this.configData.action) this.configData.action.replace('/index.json', '');

    this.configData.list && this.configData.list.map(toc => {
      toc['disable'] = currentUrl.length !== 0 && currentUrl !== '/' && (this.configData.currentEpisodeID === toc.id);
      toc.image = toc.image.includes(COMIC_IMG_API) || this.newComicService.isExternalImage(toc.image)
        ? toc.image
        : (toc.image ? COMIC_IMG_API + toc.image : '');
      toc.action = toc.action.replace('/index.json', '');
      toc.isRead = toc.isRead || false;
    });
    this.showScrollbar(this.scrollContainer);
  }

  public showScrollbar($event): void {
    const classes = $event && $event.classList;
    classes && classes.add('show-scrollbar');
    classes && classes.remove('hide-scrollbar');
  }

  private scrollToCenter(container) {
    const itemIndex = this.configData.list.findIndex(item => item.disable === true);

    if (itemIndex !== -1) {
      const itemWidth = container.scrollWidth / this.configData.list.length;
      const scrollPosition = itemIndex * itemWidth - container.offsetWidth / 2 + itemWidth / 2;

      container.scrollTo(scrollPosition, 1);
    }
  }

  public navigateTo(buttonClicked: string): void {
    switch (buttonClicked) {
      case 'All Series':
        this.router.navigate(['/tinyview/comic-series-directory']);
        break;
      case 'Following':
        this.newComicService.setsidebarItemSelected('following');
        this.router.navigate(['']);
        break;
      case 'Home':
        this.newComicService.setsidebarItemSelected('home');
        this.router.navigate(['']);
        break;
      case 'All Episodes':
        this.router.navigate([this.configData.seriesHomeNavigation]);
    };
  }

  public navigateToURL(toc) {
    const isDisabled = toc.disable;
    if (isDisabled) return;
    const URL = toc.action;
    if (this.newComicService.isExternalImage(URL)) {
      this.router.navigate(['/story/action'], { queryParams: { actionURL: URL } });
    } else {
      this.router.navigate([URL]);
    }
  }

  getImagePath(img: string) {
    return this.comicService.getCDNImgUrl(img);
  }

  onImgError(e, img) {
    e.target.src = getImage(img);
  }
  
  getIconPath(icon: string) {
    return getImage(icon);
  }
}