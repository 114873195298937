<div class="tv-card payment-container">
    <div class="inner-block">
        <div class="img-block influence">
            <img [src]="cardData.image"/>
        </div>
        <div class="desc-block influence">
            <div class="mb-3">
                <span class="p-bold">
                    {{cardData.title}}
                </span>
                <p class="mt-2">
                    {{cardData.subTitle}}
                </p>
            </div>
            <div class="subc-block">
                <img class="h-37" *ngIf="cardData.btnImage" [src]="cardData.btnImage" (click)="manageInfluence()"
                    [ngClass]="{'pointer-events-none': cardData.disabled}">
                <button *ngIf="!cardData.btnImage" class="btn tv-btn-red"
                    [ngClass]="{'disabled': false}"
                    [disabled]="cardData.disabled"
                    (click)="manageInfluence()">
                    {{cardData.btnText}}
                    <!-- LOADER <span class="loader-bar" *ngIf="subscriptionId"></span>-->
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #shareModal>
    <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="shareRef.hide()" />
    <div class="heading">
        <h2 class="share-heading" *ngIf="!isSubscriber">Share it with friends</h2>
        <h2 class="share-heading" *ngIf="isSubscriber"><img [src]="getImagePath('BADGE_GIFT_100')" class="gift-icon" alt="gift icon">Gift this comic</h2>
        <p class="share-sub-heading" *ngIf="!isSubscriber">and earn 1 influence point for each click</p>
        <p class="share-sub-heading" *ngIf="isSubscriber">and help creators by spreading the word</p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let share of shareConfig">
            <div *ngIf="share.show" class="gift-item share-item" (click)="manualShare(share)">
                <img class="share-img" [src]="share.image">
                <span class="share-title">{{ share.title }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
</ng-template>

<ng-template #inviteFriendsModal>
    <app-invite-friends [isSubscribed]="isSubscribed" (closeModal)="closeInviteFriendsModal()"></app-invite-friends>
  </ng-template>
