<div *ngIf="!isComicPage && !storyPage" class="comic-gift-container2" [ngClass]="{ 'remove-bottom-css': hideBottomSection }">
    <div class="images d-flex align-items-center">
        <ng-container *ngFor="let gift of reactionData">
            <img [src]="gift.imageSrc" *ngIf="gift.show" (click)="openGiftModal(giftModal)" />
        </ng-container>
        <span class="body-mini" (click)="openGiftModal(giftModal)">&nbsp;
            {{likeCount ? likeCount :'' | numberFormat}}
        </span>
        <span class="body-mini" *ngIf="!likeCount">Be first to like</span>
    </div>
    <div class="d-flex">
        <div class="view-count d-flex align-items-center">
            <span class="body-mini">{{viewCount | numberFormat}}&nbsp;</span>
            <p *ngIf="viewCount == 1" [class.body-mini]="isMobile">View</p>
            <p *ngIf="(viewCount == 0 || viewCount > 1)" [class.body-mini]="isMobile">Views</p>
        </div>
    </div>
    <a (click)="showALlComments(storyID, modal, commentModal, true)" class="d-flex">
        <div class="d-flex">
            <div class="comments-count d-flex align-items-center">
                <span class="body-mini">{{commentCount | numberFormat}}&nbsp;</span>
                <p *ngIf="commentCount == 1" [class.body-mini]="isMobile">Comment</p>
                <p *ngIf="(commentCount == 0 || commentCount > 1)" [class.body-mini]="isMobile">Comments</p>
            </div>
        </div>
    </a>
</div>

<!-- !hideBottomSection - as per less distraction story, we are removing it from top card on comic page -->
<div class="bottom-section2 align-items-center" *ngIf="!isCover && !hideBottomSection"
    [ngClass]="{'border-bottom': isComicPage, 'mb-4': isDirectoryPage && commentCount}">
    <!-- <a (click)="doLikeComic(storyID)"> -->
    <a (click)="openGiftArtistModal(giftArtistModal)" class="d-flex align-items-center">
        <img class="icon-20" *ngIf="!isLiked" [src]="getImagePath('LIKE_ICON')" alt="like">
        <img *ngIf="isLiked" [src]="getImagePath('RED_HEART')" alt="like">&nbsp;
        <span class="p-bold" *ngIf="likeCount"></span>
        <p *ngIf="!isLiked">Like</p>
        <p *ngIf="isLiked">Liked</p>
    </a>
    <a (click)="showALlComments(storyID, modal, commentModal, false)" class="d-flex align-items-center">
        <img class="icon-20" [src]="getImagePath('ICONS8_COMMENT')" alt="like"
            [ngStyle]="storyPage && {'cursor':'not-allowed'}">&nbsp; <span class="p-bold" *ngIf="commentCount"></span>
        <p [ngStyle]="storyPage && {'cursor':'not-allowed'}">Comment</p>
    </a>
    <a (click)="openShareModal(shareModal)" class="d-flex align-items-center">
        <!-- <a (click)="onShare()"> -->
        <img class="icon-20 mr-0" [[src]]="getImagePath('ICONS8_UPLOAD_150')" alt="like">
        <ng-container *ngIf="showShare">
            <div class="popuptext" id="myPopup">
                <a (click)="shareFacebook()" class="fa">
                    <img [src]="getImagePath('ICONS8_FACEBOOK_WHITE_50')">
                </a>
                <a (click)="shareTwitter()" class="fa">
                    <img [src]="getImagePath('ICONS8_TWITTER_WHITE_50')">
                </a>
            </div>
        </ng-container> &nbsp;
        <p>Share</p>
    </a>
</div>

<!-- !hideBottomSection - as per less distraction story, we are removing it from top card on comic page -->
<div *ngIf="isComicPage && !isSubscribePage && !hideBottomSection" class="comic-share-wrapper"
    [ngClass]="{'border-bottom': isBottomCard && commentCount}">
    <ng-container *ngFor="let share of shareConfig">
        <div *ngIf="share.show" class="comic-share-item" (click)="manualShare(share)">
            <img class="share-img" [src]="share.image">
        </div>
    </ng-container>
</div>

<div *ngIf="isComicPage || storyPage" class="comic-gift-container2 bottom-fixed" [ngClass]="{ 'desktop': !isMobile && !isSubscribePage && !isDirectoryPage, 'render-on-top': (isSubscribePage || isDirectoryPage) }">
    <div class="images d-flex align-items-center" (click)="openGiftArtistModal(giftArtistModal)">
        <img *ngIf="!isLiked && (isSubscribePage || isDirectoryPage)" class="icon-20" [src]="getImagePath('LIKE_ICON')" alt="unfilled-like">
        <img *ngIf="!isLiked && (storyPage || isComicPage) && !isSubscribePage && !isDirectoryPage" class="icon-20" [src]="getImagePath('ICONS8_HEART_100')" alt="unfilled-like">
        <img *ngIf="isLiked && (storyPage || isComicPage)" class="icon-20" [src]="getImagePath('RED_HEART')" alt="filled-like">
        <ng-container *ngFor="let gift of reactionData">
            <img [src]="gift.imageSrc" *ngIf="gift.show">
        </ng-container>
        <span class="body-mini">
            {{likeCount ? likeCount :'' | numberFormat}}
        </span>
        <span class="body-mini" *ngIf="!likeCount">0</span>
    </div>
    <a (click)="showALlComments(storyID, modal, commentModal, true)" class="d-flex">
        <div class="d-flex">
            <div class="comments-count d-flex align-items-center">
                <img *ngIf="isSubscribePage || isDirectoryPage" class="icon-20" [src]="getImagePath('ICONS8_COMMENT')" alt="comment-icon">
                <img *ngIf="!isSubscribePage && !isDirectoryPage" class="icon-20" [src]="getImagePath('ICONS8_COMMENTS_100')" alt="comment-icon">
                <span class="body-mini">{{commentCount | numberFormat}}</span>
            </div>
        </div>
    </a>
    <div class="d-flex">
        <div class="view-count d-flex align-items-center">
            <img *ngIf="isSubscribePage || isDirectoryPage" class="icon-20" [src]="getImagePath('ICON_EYE')" alt="view-icon">
            <img *ngIf="!isSubscribePage && !isDirectoryPage" class="icon-20" [src]="getImagePath('ICONS8_EYE_THIN_100')" alt="view-icon">
            <span class="body-mini">{{viewCount | numberFormat}}</span>
        </div>
    </div>
    <a (click)="openShareModal(shareModal)" class="d-flex align-items-center">
        <img *ngIf="!isBottomCard" class="icon-20 mr-0" [src]="getImagePath('ICONS8_UPLOAD_150')" alt="share-icon">
        <img *ngIf="isBottomCard && (!isSubscriber || isSeriesCreator || actionType === 'website' || isTinyviewSpecificPage)" class="icon-20 mr-0" [src]="getImagePath('ICONS8_UPLOAD_100')" alt="bottom-share-icon">
        <img *ngIf="isBottomCard && isSubscriber && !isSeriesCreator && actionType !== 'website' && !isTinyviewSpecificPage" class="icon-20 mr-0" [src]="getImagePath('ICONS8_GIFT_MEDIUM_100')" alt="bottom-share-icon">
        <ng-container *ngIf="showShare">
            <div class="popuptext" id="myPopup">
                <a (click)="shareFacebook()" class="fa">
                    <img [src]="getImagePath('ICONS8_FACEBOOK_WHITE_50')">
                </a>
                <a (click)="shareTwitter()" class="fa">
                    <img [src]="getImagePath('ICONS8_TWITTER_WHITE_50')">
                </a>
            </div>
        </ng-container>
    </a>
</div>

<ng-template #modal>
    <div>
        <app-action-popup [modalRef]="modalRef" [ctaList]="ctaList" [likedComic]="likedComic"></app-action-popup>
    </div>
</ng-template>

<ng-template #giftModal>
    <app-gift-items [storyID]="storyID" [giftModalRef]="giftModalRef" [giftItemCount]="giftItemCount2">
    </app-gift-items>
</ng-template>

<ng-template #commentModal>
    <div class="action-container">
        <div class="text-center mb-2">
            <p>You need to complete your profile first.</p>
        </div>
        <div>
            <div class="btn-container pb-0">
                <button class="secondary-btn" aria-label="Open" (click)="commentModalRef.hide()">
                    Cancel
                </button>
                &nbsp;
                <button class="btn tv-btn-red" aria-label="Open" (click)="goToSignInPage()">
                    Edit Profile
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #giftArtistModal>
    <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')"
        (click)="giftArtistModalRef.hide()" />
    <div class="heading">
        <p class="gift-artist-heading">Send love or more to the artist...</p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let gift of availableGifts">
            <div *ngIf="!gift.hide" class="gift-item" [ngClass]="{'disable-gift': alreadyGifted(gift)}"
                (click)="addReaction(gift.reactionName, gift.productId, gift.cost)">
                <img class="gift-img" src="{{ getImgUrl(gift) }}">
                <span class="gift-title">{{ getGiftTitle(gift) }}</span>
                <span class="gift-price">{{ gift.price }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
    <div *ngIf="isCheckoutURLLoading" class="loader-bar center-loader"></div>
</ng-template>

<ng-template #shareModal>
    <img class="cross-btn" [src]="getImagePath('ICONS8_DELETE')" (click)="shareRef.hide()" />
    <div class="heading">
        <h2 class="share-heading" *ngIf="!isSubscriber || isSeriesCreator || actionType === 'website' || isTinyviewSpecificPage">Share it with friends</h2>
        <h2 class="share-heading" *ngIf="isSubscriber && !isSeriesCreator && actionType !== 'website' && !isTinyviewSpecificPage"><img [src]="getImagePath('BADGE_GIFT_100')" class="gift-icon" alt="gift icon">Gift this comic</h2>
        <p class="share-sub-heading" *ngIf="!isSubscriber">and earn 1 influence point for each click</p>
        <p class="share-sub-heading" *ngIf="(isSubscriber && (actionType === 'website' || isTinyviewSpecificPage || isSeriesCreator))">and help creators by spreading the word</p>
        <p class="share-sub-heading" *ngIf="isSubscriber && !isSeriesCreator && actionType !== 'website' && !isTinyviewSpecificPage">As a premium user, you can gift comics to anyone. They can read the full comic for FREE including any bonus panels.</p>
    </div>
    <div class="gift-items">
        <ng-container *ngFor="let share of shareConfig">
            <div *ngIf="share.show" class="gift-item share-item" (click)="manualShare(share)">
                <img class="share-img" [src]="share.image">
                <span class="share-title">{{ share.title }}</span>
            </div>
        </ng-container>
    </div>
    <div class="btn-container">
    </div>
</ng-template>