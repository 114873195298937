import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-merge-user-modal',
  templateUrl: './merge-user-modal.component.html',
  styleUrls: ['./merge-user-modal.component.scss']
})
export class MergeUserModalComponent {
  constructor(
    public modalRef: BsModalRef,
  ) { }
}
