import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ComicService } from '../services/comic.service';
import { cancelCtaText, months, planChangeCtaText, planChangeHeader, planChangeMsg, planChangeMsgHelper, productIDs, stores, subscriptionButton, subscriptionLevels, subscriptionPrice } from '../constants/subscription.constants';
import { LocalStorageService } from '../services/local-storage.service';
import { SIGNIN_SUCCESS_TOAST, signInPhoneURL, subscribeURL, traceStates } from '../constants/common.constants';
import { getImage } from '../constants/images.constants';
import { isAnonymousUser } from '../utilities/common.util';
import { ActivatedRoute, Router } from '@angular/router';
import { NewComicService } from '../new-comic.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  isSubscribed: boolean;
  products: any = [];
  subscriptionId: boolean = false;
  productIds: any = [];
  id: any;
  upgradeModalRef: BsModalRef;
  signInModalRef: BsModalRef;
  selectedID: string;
  @Input() subscriptionData: any;
  public action: string;
  productIDs = productIDs;
  @ViewChild('upgradeConfirmation', { static: false }) upgradeConfirmation;
  @ViewChild('signinPopup', { static: false }) signinPopup;
  public isUpgradeAction: boolean;
  public isCancellationAction: boolean;
  public subscriptionLevels = subscriptionLevels;
  public planChangeMsg = planChangeMsg;
  public planChangeHeader = planChangeHeader;
  public cancelCtaText = cancelCtaText;
  public planChangeCtaText = planChangeCtaText;
  public subscriptions = [];
  requestedProductLevel: number;
  currentProductLevel: number;
  currentUrl: string;
  isExpired: boolean;
  isSubscribePage: boolean;
  signInBeforeSubscription: any;
  plan: any;
  showOverlay: boolean;

  constructor(
    private readonly comicService: ComicService,
    private readonly newComicService: NewComicService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.signInBeforeSubscription = this.route.snapshot.queryParams.signInBeforeSubscription;
    this.plan = this.route.snapshot.queryParams.plan;
    this.getUserDetails();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.isSubscribePage = this.currentUrl.indexOf(subscribeURL) > -1;
  }

  public async getUserDetails() {
    const val = await this.comicService.getUserDetails()
    const pIDs = [];
    if (val.data.subscriptions.length) {
      this.subscriptions = val.data.subscriptions;
      val.data.subscriptions.map((res) => {
        this.productIds.push({
          id: res.productID,
          store: res.store,
          autoRenew: res.autoRenew
        });
        pIDs.push(res.productID);
      });
    }
    this.handleSubscriptionBeforeSignInFlow(this.subscriptions);

    this.currentUrl = this.comicService.getCurrentUrl();
    if (this.currentUrl.indexOf(subscribeURL) > -1) { // for directory page handling
      if (![traceStates.STATE_UNSPECIFIED, traceStates.COMPLETED].includes(this.comicService.pageLoadTrace && this.comicService.pageLoadTrace.state)) {
        this.comicService.pageLoadTrace.stop();
      }
    }
    this.localStorageService.setItem('productID', JSON.stringify((pIDs)));
    this.comicService.productIDs$.next(JSON.stringify((pIDs)));
    if (this.productIds[0]) this.id = this.productIds[0].id;
  }

  handleSubscriptionBeforeSignInFlow(subscriptions: any = []) {
    // HANDLING THESE CASES -
    // 1. If user is SUBSCRIBED and If same plan Do Nothing
    // 2. If user is SUBSCRIBED and upgrading
    // 3. If user is SUBSCRIBED and downgrading
    // 4. If user is NOT SUBSCRIBED
    if (this.signInBeforeSubscription && this.plan && this.plan === this.subscriptionData.inAppPurchase) {
      if (subscriptions.length && subscriptions[0].productID === this.plan) {
        this.toastr.success(SIGNIN_SUCCESS_TOAST);
        // If same plan Do Nothing
        return;
      }

      if (!subscriptions.length) {
        // If user is NOT SUBSCRIBED
        this.newComicService.toggleOverlay();
        this.toastr.success('You are signed in. Please wait while we take you to the payments page.', null, { timeOut: 10000 });
        setTimeout(() => {
          this.checkOut(this.plan, false, false);
        }, 5000);
      } else {
        // If user is SUBSCRIBED and upgrading or downgrading
        this.toastr.success(SIGNIN_SUCCESS_TOAST);
        const isSubscribedViaStripe = !!this.productIds.find(product => product.store == 'Stripe');
        if (isSubscribedViaStripe) this.checkOut(this.plan, false, false);
      }
    }
    this.clearQueryParams();
  }

  clearQueryParams(): void {
    // Clear query params without reloading the page
    this.router.navigate([subscribeURL], {
      queryParams: {}, // Clear all query parameters
      // queryParamsHandling: 'merge', // Prevent page reload and just update query params
      // skipLocationChange: true, // This will not reload the page
    });
  }

  public upgradeSubscription(productId: string, newproductId: string) {
    this.comicService.getUpgradeSubscription(productId, newproductId).then((res) => {
      this.subscriptionId = false;
      setTimeout(() => {
        location.reload();
      }, 2000);
    })
  }

  public cancelSubscription(productId: string) {
    this.comicService.getCancelSubscription(productId).then((res) => {
      this.subscriptionId = false;
      setTimeout(() => {
        location.reload();
      }, 2000);
    })
  }

  openSignInModal() {
    const isMobile = this.newComicService.isMobileView() ? 'mobile-modal' : '';

    this.signInModalRef = this.modalService.show(this.signinPopup, Object.assign({}, {
      class: `modal-md ${isMobile}`,
      ignoreBackdropClick: true,
      animation: !isMobile
    }));
  }

  public checkOut(id: string, planChangeConfirm?: boolean, cancellationRequest?: boolean) {
    if (isAnonymousUser() && !cancellationRequest) {
      this.selectedID = id;
      this.openSignInModal();
      return;
    }
    if (!this.productIds.length) {
      if (this.comicService.userDetails && this.comicService.userDetails.data && this.comicService.userDetails.data.subscriptions && this.comicService.userDetails.data.subscriptions.length) {
        this.subscriptions = this.comicService.userDetails.data.subscriptions;
        this.comicService.userDetails.data.subscriptions.map((res) => {
          this.productIds.push({
            id: res.productID,
            store: res.store,
            autoRenew: res.autoRenew
          });
        });
      }
    }
    if (this.isSubscribed && !(cancellationRequest || this.isCancellationAction)) return;

    const alreadySubscribed = this.productIds.find(product => product.id === id);
    if (alreadySubscribed && !(cancellationRequest || this.isCancellationAction)) return;

    this.requestedProductLevel = subscriptionLevels.indexOf(this.getFinalProductID(id));
    let highestSub = '';
    if (this.productIds && this.productIds.length) {
      highestSub = this.comicService.getHighestSubscriptionFromObject(this.productIds);
    }
    this.currentProductLevel = (this.productIds && this.productIds[0] && highestSub) ? subscriptionLevels.indexOf(this.getFinalProductID(highestSub)) : -1;

    const isDownGrading = this.requestedProductLevel < this.currentProductLevel;
    this.isUpgradeAction = !cancellationRequest && !isDownGrading;
    this.action = cancellationRequest ? 'Cancel' : (this.isUpgradeAction ? 'Upgrade' : 'Downgrade');
    this.selectedID = id;
    this.subscriptionId = true;
    const isSubscribedViaStripe = !!this.productIds.find(product => product.store == 'Stripe');
    if (this.productIds.length && isSubscribedViaStripe) {
      if (planChangeConfirm) {
        this.upgradeModalRef.hide();
        if (this.isCancellationAction) {
          this.cancelSubscription(id);
        } else {
          this.isCancellationAction = false;
          const stripeSubscripton = this.productIds.find(productID => productID.store === stores.STRIPE);
          this.upgradeSubscription(stripeSubscripton.id, id);
        }
      } else {
        this.openUpgradeConfirmationModal();
      }
    } else {
      this.localStorageService.setItem('productID', JSON.stringify(([])));
      this.localStorageService.setItem('subscription_data', { id, value: this.subscriptionData.price });
      this.comicService.getCheckoutURL(id).then((res) => {
        const url = res.data.data.url;
        if (url) {
          this.subscriptionId = false;
          window.open(url, "_self");
        }
      });
    }
  }

  public getPrice(productName: string) {
    switch (productName) {
      case productIDs.cookie:
        return subscriptionPrice.cookie;
      case productIDs.bagel:
        return subscriptionPrice.bagel;
      case productIDs.coffee:
        return subscriptionPrice.coffee;
      case productIDs.artsupplies:
        return subscriptionPrice.artsupplies;
      case productIDs.pizza:
        return subscriptionPrice.pizza;
      default:
        return subscriptionPrice.pizza;
    }
  }

  public getButtonText(productName: string) {
    let subscribedFromApp = false;
    if (this.productIds.find(productID => (productID.id === productName) && productID.store !== stores.STRIPE)) {
      subscribedFromApp = true;
      // return "Subscribed from app";
    }
    if (this.checkIfSubscribed(productName) && !subscribedFromApp && this.productIds.find(productID => (productID.id === productName) && (productID.store === 'Stripe' && !productID.autoRenew))) {
      this.isCancellationAction = true;
      return "Subscription Cancelled";
    }
    if (this.checkIfSubscribed(productName) && !subscribedFromApp) {
      this.isCancellationAction = true;
      return "Cancel Subscription";
    }
    this.isCancellationAction = false;
    switch (productName) {
      case productIDs.cookie:
        return subscriptionButton.cookie;
      case productIDs.bagel:
        return subscriptionButton.bagel;
      case productIDs.coffee:
        return subscriptionButton.coffee;
      case productIDs.artsupplies:
        return subscriptionButton.artsupplies;
      case productIDs.pizza:
        return subscriptionButton.pizza;
      default:
        return subscriptionButton.pizza;
    }
  }

  public getPriceClass(productName: string) {
    let subscribedFromApp = false;
    if (this.productIds.find(productID => (productID.id === productName) && productID.store !== stores.STRIPE)) {
      subscribedFromApp = true;
      // return "Subscribed from app";
    }
    if (this.checkIfSubscribed(productName) && !subscribedFromApp) {
      this.isCancellationAction = true;
      return "btn tv-btn-light";
    }
    return 'btn tv-btn-red';
  }

  public setAction(action: string) {
    this.action = action;
  }

  public checkForDisabling (productName: string) {
    if (this.productIds.find(productID => (productID.id === productName) && (productID.store !== 'Stripe' || (productID.store === 'Stripe' && !productID.autoRenew)))) {
      return true;
    }

    return false;
  }

  public getExpiryAfterCancellation (productName: string) {
    if (this.productIds.find(productID => (productID.id === productName) && (productID.store === 'Stripe' && !productID.autoRenew))) {
      return true;
    }

    return '';
  }

  public getRenewal(renewalString: string, productName:string): string {
    return (this.productIds.find(productID => (productID.id === productName) && (productID.store === 'Stripe' && !productID.autoRenew)))
    ? 'Your subscription has been cancelled. Please continue to enjoy the benefits until the end of this billing cycle.'
    : renewalString;
  }

  public getExpiryDate(productName: string) {
    const subscribedProduct = this.subscriptions.find(sub => sub.productID === productName);
    const subscribedDate = new Date(subscribedProduct.expiringAt);
    const year = subscribedDate.getFullYear();
    const month = months[subscribedDate.getMonth()];
    const date = subscribedDate.getDate();
    return { year, month, date };
  }

  public getExpiry(productName: string, below?: boolean): string {
    const subscribedProduct = this.subscriptions.find(sub => sub.productID === productName);
    if (subscribedProduct && subscribedProduct.store === 'Stripe' && subscribedProduct.autoRenew && !below) {
      const expiryDate = this.getExpiryDate(productName);
      return `Renews ${expiryDate.month} ${expiryDate.date}`;
    } else if (subscribedProduct && subscribedProduct.store === 'Stripe' && !subscribedProduct.autoRenew && below) {
      this.isExpired = true;
      const expiryDate = this.getExpiryDate(productName);
      return `Expiring ${expiryDate.month} ${expiryDate.date}, ${expiryDate.year}`;
    }
    return ``;
  }

  public checkIfSubscribed(productID: string): boolean {
    const prodIds = this.localStorageService.getItem('productID') || [];
    if (prodIds.includes(productID)) {
      this.isSubscribed = true;
      return true;
    }
    this.isSubscribed = false;
    return false;
  }

  openUpgradeConfirmationModal() {
    this.upgradeModalRef = this.modalService.show(this.upgradeConfirmation, Object.assign({}, {
      class: 'text-app-modal',
      ignoreBackdropClick: true
    }));
  }

  public closePlanChangeConfirmModal(ref: BsModalRef) {
    this.closeModal(ref);
    this.subscriptionId = false;
  }

  public closeModal(ref: BsModalRef) {
    ref.hide();
  }

  public getPlanChangeMsg(action: string) {
    let msg = planChangeMsg[action];
    switch (action) {
      case 'Cancel':
        const expiryDate = this.getExpiryDate(this.subscriptionData && this.subscriptionData.inAppPurchase);
        return msg.replace('{{variable}}', `${expiryDate.month} ${expiryDate.date}, ${expiryDate.year}`);
      case 'Downgrade':
        msg = msg.replace('{{current}}', planChangeMsgHelper[subscriptionLevels[this.currentProductLevel]]);
        msg = msg.replace('{{requested}}', planChangeMsgHelper[subscriptionLevels[this.requestedProductLevel]]);
        return msg;
      case 'Upgrade':
        msg = msg.replace('{{current}}', planChangeMsgHelper[subscriptionLevels[this.currentProductLevel]]);
        msg = msg.replace('{{requested}}', planChangeMsgHelper[subscriptionLevels[this.requestedProductLevel]]);
        return msg;
      default:
        return msg;
    }
  }

  getFinalProductID (pID: string): string {
    if (pID === 'com.tinyview.subscription') {
      return 'cookie';
    }
    return pID.split('.')[pID.split('.').length - 1];
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }

  navigateToSignIn() {
    this.router.navigate([signInPhoneURL], { queryParams: { signInBeforeSubscription: true, plan: this.selectedID }});
    this.closeModal(this.signInModalRef);
  }
}
