import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NewComicService } from '../new-comic.service';
import { Router } from '@angular/router';
import { PanelService } from '../services/panel/panel.service';
import { camelToHyphen, isAnonymousUser } from '../utilities/common.util';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ACTIONS } from '../constants/common.constants';
import { UserService } from '../services/user.service';
import { ComicService } from '../services/comic.service';

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss', '../payments/payments.component.scss']
})
export class TemplateCardComponent implements OnInit {
  @ViewChild('inviteFriendsModal', { static: false }) inviteFriendsModal;
  @Input() data: any;
  @Input() customStyles: any;
  public isSubscribePage: boolean;
  public isAnonymous: boolean;
  public isMobile: boolean;
  public inviteFriendsRef: any;
  subscriptions = [];
  openInStore: boolean;
  enableOpenInStore: boolean;

  constructor(
    private readonly newComicService: NewComicService,
    private readonly comicService: ComicService,
    private readonly router: Router,
    private readonly panelService: PanelService,
    private modalService: BsModalService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.isSubscribePage = this.newComicService.isSubscribePage();
    if (this.isSubscribePage) {
      const userData = await this.userService.getUserDetails();
      this.subscriptions = userData.data.data.subscriptions;
    }
    this.isMobile = this.newComicService.isMobileView();
    this.isAnonymous = isAnonymousUser();

    this.openInStore = this.data.actionType === 'appStore';
    this.enableOpenInStore = this.openInStore && this.newComicService.isMobileView();
  }

  redirectToUrl(url: string): void {
    if (url === 'inviteFriends') {
      this.openInviteFriendsModal(this.inviteFriendsModal);
      return;
    }

    // Add more actions to ACTIONS constant to ensure proper redirection for future cases
    if (ACTIONS.includes(url)) {
      const pageToRedirect = camelToHyphen(url);
      this.router.navigate([`/tinyview/${pageToRedirect}`]);
      return;
    }

    // Use the router to navigate to the specified URL
    if (url.indexOf('http') > -1) {
      window.open(url, "_blank");
    } else {
      url = url.replace('/index.json', '');
      this.router.navigate([url]);
    }
  }

  public getImgUrl(data: any) {
    return this.panelService.getImgUrl(data.image || data, '/' + (data.series ? data.series : ''));
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymous ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  public applyLH(): boolean {
    return this.data && this.data.title && this.data.description && (this.customStyles && this.customStyles.descriptionLH != 'normal' || !this.customStyles);
  }

  isHighlightPanel(data: any) {
    const highlightPanel = data || [];
    if (this.subscriptions.length === 0) {
      return highlightPanel && highlightPanel.length !== 0 && !highlightPanel[0]['com.tinyview.subscription'];
    }
    // Check if any of the subscriptions have a valid (true) product ID
    return this.subscriptions.some((sub) => {
      return highlightPanel.length !== 0 && highlightPanel.some((item: any) => {
        const productID = Object.keys(item)[0];
        return productID === sub.productID && item[productID] === true;
      });
    });
  }

  redirectToStore() {
    this.comicService.openInStore();
  }
}
