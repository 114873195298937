import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { subscribeURL, templateTypes } from '../constants/common.constants';
import { ComicService } from '../services/comic.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalConfig } from '../interfaces/user.interface';
import { ModalService } from '../services/modal.service';
import * as util from '../utilities/common.util';
import { LocalStorageService } from '../services/local-storage.service';
import { UserService } from '../services/user.service';
import { NewComicService } from '../new-comic.service';
const COMIC_IMG_API = `${environment.API_END_POINT}`;

@Component({
  selector: 'app-comic-bonus',
  templateUrl: './comic-bonus.component.html',
  styleUrls: ['./comic-bonus.component.scss']
})
export class ComicBonusComponent implements OnInit {
  @Input() templateType?: string;
  @Input() series;

  currentUrl: string = '';
  bonusPanelImage: string;
  subscribeURL = subscribeURL;
  unlockBonusPanelRef: BsModalRef;
  currentDevice: string;
  isComicUnlocking: boolean;
  influencePointsBalance: number;
  anonymousUser: boolean;
  public actionSheetConfig: ModalConfig = {
    header: '',
    subHeader: '',
    buttons: [],
    series: {},
    unlockBonusPanel: false
  };
  isSubscriber: boolean;
  isMobile: boolean;

  constructor(
    private comicService: ComicService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    public newComicService: NewComicService
  ) { }

  ngOnInit() {
    this.currentUrl = this.comicService.getCurrentUrl();
    this.influencePointsBalance = (this.comicService.influencePoints && this.comicService.influencePoints.balance) || 0;
    this.anonymousUser = util.isAnonymousUser();
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
    this.setDefaultActionSheetConfig();
    this.isMobile = this.newComicService.isMobileView()
  }

  public async setDefaultActionSheetConfig() {
    let path = COMIC_IMG_API;
    this.bonusPanelImage = path + '/tinyview/app/bonus-panel-with-button.jpg';
    this.actionSheetConfig.header = 'Unlock Bonus Panel';
    const userData = await this.userService.getUserDetails();
    const alerts = userData.data.data.alerts;
    const normalizedSeries = this.series && this.series.action.replace(/^\/|\/index\.json$/g, '');
    const isCurrentSeriesFollower = Object.keys(alerts).includes(normalizedSeries);
    const hasAnyChannel = this.comicService.userHasAnyChannel(userData.data);
    const followerButNoChannel =  isCurrentSeriesFollower && !hasAnyChannel;
    // Unlock Bonus Panel config as default 
    if ((this.templateType === templateTypes.bonus) && !isCurrentSeriesFollower) {
      this.actionSheetConfig.subHeader = `Follow ${this.series.title} to unlock bonus panels.`;
      this.actionSheetConfig.series = this.series || {};
      this.actionSheetConfig.buttons.push({
        text: `Follow ${this.series.title}`,
        action: 'follow',
      });
      this.actionSheetConfig.unlockBonusPanel = true
    }

    if ((this.templateType === templateTypes.bonus) && followerButNoChannel) {
      this.actionSheetConfig.subHeader = `You're following ${this.series.title}, but we are unable to send you alerts.`;
      this.actionSheetConfig.series = this.series || {};
      this.actionSheetConfig.buttons.push({
        text: 'Manage Alerts',
        action: 'alerts-setting',
      });
      this.actionSheetConfig.unlockBonusPanel = true
    }

    this.actionSheetConfig.buttons.push({
      text: 'Upgrade to a Premium Plan',
      action: 'subscribe'
    });

    if (this.templateType !== templateTypes.premium) {
      this.actionSheetConfig.buttons.push({
        text: this.influencePointsBalance < 1 ? 'Earn Influence Points' : 'Use 1 Influence Point',
        action: this.influencePointsBalance < 1 ? 'earnInfluencePoints' : 'useInfluencePoints'
      });
    }

    // Unlock Premium Comic config
    if (this.templateType === templateTypes.premium) {
      this.actionSheetConfig.header = 'Unlock Comic';
      this.bonusPanelImage = path + '/tinyview/app/tinyview-premium-comic-with-button.jpg';
      this.actionSheetConfig.buttons.push({
        text: 'Use 5 Influence Points',
        action: 'useInfluencePoints',
        disabled: this.influencePointsBalance < 5
      });
      this.actionSheetConfig.buttons.push({
        text: 'Earn Influence Points',
        action: 'earnInfluencePoints' 
      });
    }
    // For anonymous User only
    if (this.anonymousUser && !this.isSubscriber) {
        this.actionSheetConfig.buttons.push({
        text: ' I am a Premium user',
        action: 'redirectToSignIn'
      });
    }
    this.actionSheetConfig.buttons.push({
      text: 'No Thanks',
      action: 'closeModal',
      class: 'cancel'
    });

    this.actionSheetConfig.modalData = {
      influencePointsBalance: this.influencePointsBalance
    };

    // Unlock Unlimited Comic config
    if (this.templateType === templateTypes.comicLimit) {
      this.actionSheetConfig.header = 'Get Unlimited Access';
      this.actionSheetConfig.subHeader = `Sign Up or Sign In using email or phone for unlimited access. It's free. Also, earn 5 influence points when you Sign Up and use them to unlock bonus panels.`;
      this.actionSheetConfig.buttons = [];
      this.actionSheetConfig.buttons.push({
        text: 'Sign Up',
        action: 'redirectToSignUp'
      });
      this.actionSheetConfig.buttons.push({
        text: 'Sign In',
        action: 'redirectToSignIn'
      });
      this.actionSheetConfig.buttons.push({
        text: 'Not Now',
        action: 'closeModal',
        class: 'cancel'
      });

      this.bonusPanelImage = path + '/tinyview/app/tinyview-free-comics-limit-with-button.jpg';
    }
  }

  public showActionSheet() {
    // console.log('Triggering showActionSheet with config:', this.actionSheetConfig); // Debug statement
    this.modalService.showActionSheetModal(this.actionSheetConfig);
  }
}
