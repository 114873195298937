<form [formGroup]="phoneForm" (ngSubmit)="logIn(phoneForm)">
  <!-- Phone number input with country code dropdown attached to it -->
  <p class="phone-label body-mini">
      Phone Number*
      <!-- <span *ngIf="currentFlow !== 'ALERTS'" class="ml-2 undeline-link" (click)="navigateTo()">
          Use email address instead
      </span> -->
  </p>
  <div class="mt-1 pt-1">
      <ngx-intl-tel-input [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
          [enablePlaceholder]="false" [searchCountryFlag]="true"
          [cssClass]="phoneNumCodeErr ? 'form-control input-value-invalid' : phoneForm.value.phone ? 'form-control input-value-present' : 'form-control'"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [selectFirstCountry]="true" [selectedCountryISO]="isoCountryCode" [maxLength]="maxPhoneLength"
          [phoneValidation]="false" [separateDialCode]="true" name="phone" formControlName="phone"
          customPlaceholder="Enter your phone no." (keyup)="phoneKeytab($event)" autocomplete="off"
          (paste)="onPhoneNumberPaste()" [numberFormat]="PhoneNumberFormat.International" type="number">
      </ngx-intl-tel-input>
  </div>

  <!-- Error block which will be highlighted in red color -->
  <div *ngIf="phoneNumCodeErr" class="body-mini error-text mt-1">
      <img [src]="getImagePath('ICONS8_EXCLAMATION_100')" class="error-icon">
      <span *ngIf="isNewSignUp">
        <span *ngIf="!isSubscriber">
            &nbsp; Phone number not found. Please <span class="tv-link" (click)="navigateConditionally()">Sign Up</span>
            instead.
        </span>
        <span *ngIf="isSubscriber">
            &nbsp; You're a premium user but please <span class="tv-link" (click)="navigateConditionally()">Sign Up</span>
            to enjoy full benefits.
        </span>
      </span>
      <span *ngIf="isNewSignIn">
          &nbsp;Already a user. Please <span class="tv-link" (click)="navigateConditionally()">Sign
              In</span> instead.
      </span>
      <span *ngIf="!isNewSignIn && !isNewSignUp">&nbsp;{{phoneNumCodeErr}}</span>
  </div>

  <!-- Showing this permanently until there is any error -->
  <div *ngIf="!phoneNumCodeErr" class="body-mini mt-1">We will send verification code to this number.
  </div>

  <!-- Button to send code -->
  <button [disabled]="phoneForm.get('phone').invalid || !phoneForm.get('phone').value || phoneNumCodeErr"
      class="btn tv-btn-red send-btn" id="send-otp">
      <span class="loader-bar" *ngIf="!fetchDone"></span>
      <span class="p-bold send-btn-text" *ngIf="fetchDone">Send Verification Code</span>
  </button>

  <!-- This is a conditional block depending on SIGNIN/SIGNUP flow -->
  <!-- <p *ngIf="currentFlow === 'SIGNIN'" class="mt-20 text-center body-mini">
      New user? <span class="tv-link" (click)="navigateConditionally()">Sign Up</span>
  </p> -->
</form>
<div class="mt-58">
    <div class="d-flex"><div class="separator-line"></div><div class="mx-2">or</div><div class="separator-line"></div></div>
    <div>
        <button class="btn tv-btn-red send-btn" (click)="navigateToMailSignin()"><span class="p-bold"><img [src]="getImagePath('ICONS8_EMAIL_100_FFFFFF')" alt="Email-icon" class="social-icon">Sign in with Email</span></button>
        <button class="btn dark-btn send-btn dark my-3" (click)="googleLogin()"><div *ngIf="googleLoading" class="mx-auto loader-dots-white" alt="loader"></div><span class="p-bold" *ngIf="!googleLoading"><img [src]="getImagePath('ICONS8_GOOGLE_100')" alt="Google-Icon" class="social-icon">Sign in with Google</span></button>
        <button class="btn dark-btn send-btn dark mt-0" (click)="appleLogin()"><div *ngIf="appleLoading" class="mx-auto loader-dots-white" alt="loader"></div><span class="p-bold" *ngIf="!appleLoading"><img [src]="getImagePath('ICONS8_APPLE_100_FFFFFF')" alt="Apple-Icon" class="social-icon">Sign in with Apple</span></button>
    </div>
</div>