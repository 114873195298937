import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { subscriptionAnonymousMsg } from '../constants/subscription.constants';
import { ComicService } from '../services/comic.service';
import { getImage } from '../constants/images.constants';
import { LoginService } from '../services/login.service';
import { LOGIN_FLOW } from '../constants/common.constants';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss', '../comic/comic.component.scss']
})
export class SigninComponent implements OnInit {

  // Query params related vars
  public redirectionString: string;
  public topMessage: string;

  // SignIn/SignUp config object
  public config = {
    SIGNIN: {
      screen: {
        title: 'Sign In'
      }
    },
    ALERTS: {
      screen: {
        title: 'Phone Number'
      }
    },
    SUBSCRIPTION: {
      screen: {
        title: 'Phone Number'
      }
    }
  };

  // SignIn/SignUp related vars
  public currentFlow: string = 'SIGNIN';
  public currentConfig: any = this.config[this.currentFlow];
  signInBeforeSubscription: any;
  plan: any;

  constructor(
    private route: ActivatedRoute,
    private readonly comicService: ComicService,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.initializeSignIn();
  }




  // Initializing signin page and its data
  public async initializeSignIn() {
    const url = this.comicService.getCurrentUrl();
    // Below code block is used when we redirect user to SignIn page to see and accept friend request from his/her friend
    if (this.route.snapshot.queryParams.message) {
      this.topMessage = this.route.snapshot.queryParams.message;
    }
    if (this.route.snapshot.queryParams.showSubscriptionMsg) {
      this.redirectionString = subscriptionAnonymousMsg;
    }
    // Need to move this into getCurrentFlow function
    this.signInBeforeSubscription = this.route.snapshot.queryParams.signInBeforeSubscription;
    this.plan = this.route.snapshot.queryParams.plan;
    const IS_SUBSCRIPTION_FLOW = this.plan && this.signInBeforeSubscription;
    this.currentFlow = IS_SUBSCRIPTION_FLOW ? LOGIN_FLOW.SUBSCRIPTION : this.loginService.getCurrentFlow(url);

    this.currentConfig = this.config[this.currentFlow];
    const otpPattern = /\/otp$/;
    if (otpPattern.test(url)) {
      this.config.ALERTS.screen.title = 'Enter Verification Code';
    }
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
