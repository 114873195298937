import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getImage } from 'src/app/constants/images.constants';

@Component({
  selector: 'app-tv-header',
  templateUrl: './tv-header.component.html',
  styleUrls: ['./tv-header.component.scss']
})
export class TvHeaderComponent implements OnInit {

  constructor(
    private readonly router: Router,

  ) { }

  ngOnInit() {
  }

  public goToHome() {
    const currentUrl = this.router.url.split("?")[0];
    if (["", "/"].includes(currentUrl)) {
      window.location.reload();
    } else {
      this.router.navigate(['']);
    }
  }

  getImagePath(icon: string) {
    return getImage(icon)
  }
}
