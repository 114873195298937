import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LOGIN_FLOW, SIGNIN_SUCCESS_TOAST, absoluteSignUpURL, subscribeURL } from "src/app/constants/common.constants";
import { getImage } from "src/app/constants/images.constants";
import { NewComicService } from "src/app/new-comic.service";
import { AuthService } from "src/app/services/auth.service";
import { ComicService } from "src/app/services/comic.service";
import { FirebaseService } from "src/app/services/firebase.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LoginService } from "src/app/services/login.service";
import { WindowService } from "src/app/services/window.service";


@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss', './../../signin/signin.component.scss']
})
export class OtpComponent implements OnInit {
  verificationForm: FormGroup;
  codefetchDone: boolean = true;
  verifyCodeErr: string;
  message: string;
  comicSeries: string;
  currentFlow: string;
  public otpFormInputs = {
    digit1: {
      previousValue: null,
      currentValue: null
    },
    digit2: {
      previousValue: null,
      currentValue: null
    },
    digit3: {
      previousValue: null,
      currentValue: null
    },
    digit4: {
      previousValue: null,
      currentValue: null
    },
    digit5: {
      previousValue: null,
      currentValue: null
    },
    digit6: {
      previousValue: null,
      currentValue: null
    },
  }
  phoneFormNumber: string;
  fetchDone = true;
  otpSentMsg: string;
  isMobile: boolean;
  captchaVerifier: any;
  isoCountryCode: any;
  phoneNum: any;
  plan: any;
  signInBeforeSubscription: any;
  redirectionType: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private readonly comicService: ComicService,
    private newComicService: NewComicService,
    private router: Router,
    private loginService: LoginService,
    private win: WindowService,
    private fbService: FirebaseService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    public authService: AuthService
  ) {
    this.comicSeries = this.route.snapshot.queryParams.comic;
    this.message = this.route.snapshot.queryParams.message;
    this.signInBeforeSubscription = this.route.snapshot.queryParams.signInBeforeSubscription;
    this.plan = this.route.snapshot.queryParams.plan;
    this.redirectionType = this.route.snapshot.queryParams.redirectionType;
    // console.log(this.signInBeforeSubscription);
    // console.log(this.plan);
    
    this.isMobile = this.newComicService.isMobileView();
    this.createVerificationForm();
    this.currentFlow = this.loginService.getCurrentFlow(this.comicService.getCurrentUrl());

    // Replacement of subscription
    this.getPhoneNumFromQuery();
  }

  ngOnInit() {
    // Redirect if no confirmation result is there
    // TODO(prince): add user number as well
    if (!this.win.windowRef || !this.win.windowRef.confirmationResult) {
      this.router.navigate(['/signin/phone/number'], { queryParams: this.comicSeries ? { message: this.message, comic: this.comicSeries }: {} });
    }

  }

  getPhoneNumFromQuery() {
    if (this.route.snapshot.queryParams.phone164Number) {
      const data = this.route.snapshot.queryParams.phone164Number;
      this.phoneFormNumber = data;
      this.otpSentMsg = `Verification code sent to <span class="p-bold">${data}</span>`
    } else {
      this.loginService.navigateTo('phone/number', this.currentFlow, {});
    }
    if (
      this.route.snapshot.queryParams.isoCountryCode
      && this.route.snapshot.queryParams.phoneNumber
    ) {
      this.isoCountryCode = this.route.snapshot.queryParams.isoCountryCode;
      this.phoneNum = this.route.snapshot.queryParams.phoneNumber;
    }
  }

  // 6 input box OTP form
  public createVerificationForm(): void {
    this.verificationForm = this.fb.group({
      digit1: ['', Validators.required],
      digit2: ['', Validators.required],
      digit3: ['', Validators.required],
      digit4: ['', Validators.required],
      digit5: ['', Validators.required],
      digit6: ['', Validators.required]
    });
  }

  // For getting verification code from those 6 box OTP form
  private readonly getVerificationCode = (code: Object): string => {
    let _code = '';
    for (const key in code) {
      if (key) {
        _code += code[key];
      }
    }
    return _code;
  }

  // Used to verify OTP enetered by user
  public async verifyCode(verificationForm: FormGroup, event: KeyboardEvent) {
    this.codefetchDone = false;
    const element = event.target as HTMLElement;
    if (element.tagName !== 'BUTTON' || element.getAttribute('type') !== 'submit') {
        event.preventDefault(); // Prevent form submission when Enter key pressed on non-submit buttons
    }
    // Verification code starts from here
    let code: string = verificationForm && this.getVerificationCode(verificationForm.value).toString();
    try {
      await this.authService.loginWithPhoneOTP(code, this.phoneFormNumber, this.win.windowRef.confirmationResult);
      this.successRedirection();
    } catch (error) {
      this.codefetchDone = true;
      this.verifyCodeErr = error;
    }
  }

  successRedirection() {
    this.localStorageService.setItem('isAnonymousUser', false);
    if (this.currentFlow === LOGIN_FLOW.ALERTS) {
      return this.router.navigate(['manage-alerts'], { queryParams: { phoneVerificaitonState: 'success' } });
    } else if (this.currentFlow === LOGIN_FLOW.SIGNIN) {
      if (this.signInBeforeSubscription && this.plan) {
        const queryParams = { signInBeforeSubscription: this.signInBeforeSubscription, plan: this.plan };
        this.router.navigate([subscribeURL], { queryParams });
        return;
      } else {
        this.successfulLogin();
      }
    } else if (this.currentFlow === LOGIN_FLOW.SIGNUP) {
      // const param = { last4DigitPhone: this.phoneFormNumber ? `x${this.phoneFormNumber.substr(-4)}` : null }
      // this.router.navigate([`${absoluteSignUpURL}/profile`], { queryParams: this.comicSeries ? { message: this.message, comic: this.comicSeries, ...param }: {...param} });
      // this.router.navigate(['signup'], { queryParams: this.comicSeries ? { message: this.message, comic: this.comicSeries, success: true }: null });
      this.successfulLogin();
    } else {
      this.router.navigate(['/']);
    }
  }

  public successfulLogin() {
    this.toastr.success(SIGNIN_SUCCESS_TOAST);
    this.comicService.getUserDetails().then(res => {
      const userDetails = res.data;
      const pIDs = [];
      if (userDetails.subscriptions.length) {
        userDetails.subscriptions.map((res) => {
          pIDs.push(res.productID);
        });
      }
      if (pIDs.length) this.localStorageService.setItem('productID', JSON.stringify((pIDs)));
    });
    let queryParams = {};
    if (this.message) {
      queryParams = { queryParams: { message: this.message } };
    }
    if (this.redirectionType === 'friend_request') {
      return this.router.navigate(['friend-request']);
    }
    if (this.comicSeries && ![absoluteSignUpURL, '/tinyview'].includes(this.comicSeries)) {
      return this.router.navigate([this.comicSeries], queryParams);
    } else {
      this.router.navigate(['']);
    } 
  }

  // This is used to handle different events on 6 input boxes of OTP
  public otpKeytab(event, formControlName: string): void {
    // logic for moving the cursor to prev element start
    const input = this.otpFormInputs[formControlName];

    if (!input.previousValue || (event.target.value && input.previousValue !== event.target.value)) {
      input.previousValue = event.target.value;
    }

    const pointToPrevElement = (input.previousValue && !input.currentValue) || (!input.previousValue && !input.currentValue);
    input.currentValue = event.target.value;
    // logic for moving the cursor to prev element end

    const nextElement = event.srcElement.nextElementSibling;
    const prevElement = event.srcElement.previousElementSibling;
    const keyCode = event.keyCode;
    if (nextElement && keyCode !== 8 && keyCode !== 9 && ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))) {
      nextElement.focus();
      this.verifyCodeErr = '';
    } else if (keyCode === 8) { // backspace/delete
      if (pointToPrevElement) {
        requestAnimationFrame(() => {
          prevElement.focus();
        });

      }
      this.verifyCodeErr = '';
    } else {
      return;
    }
  }

  public onInput(event) {
    // picking next element for focus
    let nextElement = event.srcElement.nextElementSibling;

    // if there is already a single value present in the box then we should ignore this code block
    if (this.verificationForm.get('digit1').value && this.verificationForm.get('digit1').value.length === 1) return;

    // exactly 6 digits should be enetered in first box
    if (!event.currentTarget.value || (event.currentTarget.value && event.currentTarget.value.length != 6)) {
      if (this.verificationForm.get('digit1').value && this.verificationForm.get('digit1').value.length > 1 && this.verificationForm.get('digit1').value.length < 6) {
        this.verificationForm.get(`digit1`).setValue(this.verificationForm.get('digit1').value.charAt(0));
      }
      return;
    };

    // pasted text or iOS recommended OTP autofill value
    const pastedText = event.currentTarget.value;


    // Distribute each character to its corresponding input field
    for (let i = 0; i < pastedText.length && i < 6; i++) {
      const digitControl = this.verificationForm.get(`digit${i + 1}`);
      if (digitControl) {
        // setting single digit one by one in all input boxes
        digitControl.setValue(pastedText.charAt(i));

        // focusing next element until it reaches to the last one for good UX
        if (i !== 5) {
          nextElement.focus();
          nextElement = nextElement.nextElementSibling;
        }
      }
    }
  }

  public clearVerifyCodeVars() {
    this.verifyCodeErr = '';
  }

  public setFirstOTPBoxFocused() {
    setTimeout(() => {
      const firstInput = document.getElementById('firstInput') as HTMLInputElement;
      firstInput && firstInput.focus();
    }, 500)
  }

  public async resend() {
    try {
      if (!this.captchaVerifier) {
        this.captchaVerifier = this.fbService.renderRecaptcha('resend-otp');
      }
      const confirmationResult = await this.authService.signInPhone(this.phoneFormNumber, this.captchaVerifier);
      this.win.windowRef.confirmationResult = confirmationResult;
      this.toastr.success('Verification code has been sent successfully.');
    } catch (error) {
      this.verifyCodeErr = error;
    }
  }

  // when user clicks on change number
  public changeNumber() {
    let queryParams: any = this.comicSeries ? { message: this.message, comic: this.comicSeries }: {}
    if (this.phoneFormNumber) {
      queryParams['isoCountryCode'] = this.isoCountryCode;
      queryParams['phoneNumber'] = this.phoneNum.replace(/\s+/g, '');
    }
    if (this.signInBeforeSubscription && this.plan) {
      queryParams = { ...queryParams, signInBeforeSubscription: this.signInBeforeSubscription, plan: this.plan };
    }
    this.loginService.navigateTo('phone/number', this.currentFlow, queryParams);
  }

  getImagePath(icon: string) {
    return getImage(icon);
  }
}
