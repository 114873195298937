import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-rss-feed',
  templateUrl: './rss-feed.component.html',
  styleUrls: ['./rss-feed.component.scss']
})
export class RssFeedComponent implements OnInit {
  xmlContent: any;
  param: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.param = this.route.snapshot.paramMap.get('series')
    if (this.param) this.fetchRSSFeed();
  }

  fetchRSSFeed(): void {
    const rssUrl = `https://storage.googleapis.com/tinyview-dev.appspot.com/${this.param}/feed.rss`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/xml',
      'Accept': 'application/xml'
    });
    this.http.get(rssUrl, { headers, responseType: 'text' })
      .pipe(
        catchError((error) => {
          console.error('Error fetching RSS feed:', error);
          this.router.navigate(['']);
          return null;
        })
      )
      .subscribe((data: Blob) => {
        this.xmlContent = data;
        const blob = new Blob([data], { type: 'application/rss+xml' }); // Convert to Blob
        const url = window.URL.createObjectURL(blob);  
        window.location.href = url; // Open XML in browser or trigger download
      });
  }
}
