import { Injectable } from "@angular/core";
import * as firebase from 'firebase/app';
import { environment } from "src/environments/environment";
import { API_KEYS, REDIRECTION_TYPES } from "../constants/common.constants";
import { NotificationType, NotificationUser } from "../constants/new-comic.interface";
import { ApiService } from "./api.service";


const COMIC_API = `${environment.IMAGE_END_POINT}`

export class Notification {

  public imageUrl: string;
  public actionType: string;
  public redirectionType: string;
  public title: string;
  public body: string;
  public storyID: string;
  public commentID: string;
  public createdAt: number;
  public notificationID: string;
  public user = {
    userID: '',
    name: '',
    image: ''
  }
  public navigationURI: string;
  public deeplinkPath: string;
  public topic: string;
  public subtitle: string;

  constructor(data: NotificationType) {
    if (data.title && data.createdAt && data.notificationID) {
      this.notificationID = data.notificationID;
      this.actionType = data.actionType;
      this.redirectionType = data.redirectionType;
      this.title = data.title;
      this.body = data.body;
      this.subtitle = data.subtitle;
      this.createdAt = data.createdAt;
      this.storyID = data.storyID || null;
      this.commentID = data.commentID || null;
      this.user.userID = data.hasOwnProperty('user') && data.user.userID ? data.user.userID : null;
      this.user.name = data.hasOwnProperty('user') && data.user.name ? data.user.name : null;
      this.user.image = data.hasOwnProperty('user') && data.user.image ? `${COMIC_API}/${data.user.image}` : data.imageUrl;
      if (data.subtitle && data.body) {
        this.body = `[${this.subtitle}] ${this.body}`;
      } else if (data.subtitle) {
        this.body = `[${this.subtitle}]`;
      } else if (data.body) {
        this.body = data.body;
      }
      // redierctionType.toLowerCase():
      // 1. 'join_friend': Redirects to USER_PROFILE_SCREEN  (not-applicable for web, currently);
      // 2. 'story': Redirects to storyPage;
      // 3. 'request_accept': Redirects to see-friends page;
      // 4. 'added_influence': Redirects to influence-points page;
      // Note: If none of the above 'redierctionType' then by default redirect to deeplinkPath (which is Comic-Page)
      if (data.redirectionType) {
        switch (data.redirectionType.toLowerCase()) {
          case 'story':
            if (data.hasOwnProperty('storyID')) {
              this.navigationURI = `story/${data.storyID}`;
            }
            break;
          case 'request_accept':
            this.navigationURI = 'see-friends';
            this.body = data.body || '';
            break;
          case 'added_influence':
            this.navigationURI = 'tinyview/influence-points';
            this.body = data.body || '';
            break;
          default:
            break;
        }
      } else {
        if (data.hasOwnProperty('deeplinkPath')) {
          data.deeplinkPath.length !== 0 ? this.navigationURI = data.deeplinkPath.split('index.json')[0] : null
        }
      }
    }
  }
}



@Injectable({
  providedIn: "root",
})

export class NotificationService {
  constructor(
    private apiService: ApiService
  ) { }
  public async getNotification(records: number = 10, startAfter?: string, fromMobileNav?: boolean ) {
    const getNotifications = await this.apiService.send(API_KEYS.GET_NOTIFICATIONS);
    const res = await getNotifications(
      {
        "startAfter": startAfter || "", // last sent notificationID in previous call
        "records": records,
        "countOnly": fromMobileNav ? 1 : 0
      });
    return res.data;
  }

  public async updateLastSeenNotificationAt(seenAt: number) {
    const updateLastSeenNotificationAt = await this.apiService.send(API_KEYS.UPDATE_LAST_SEEN_NOTIFICATION_AT);
    const res = await updateLastSeenNotificationAt(
      {
        "notificationLastSeenAt": seenAt // seenAt is in UTC millisecond
      });
    return res;
  }
}


